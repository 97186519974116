export default class LocalStorage {
  static setItem(key: string, value: string): void {
    try {
      if (typeof localStorage !== "undefined") {
        localStorage.setItem(key, value);
      }
    } catch (error) {
      console.error(`Failed to set value for key ${key}. Error:`, error);
    }
  }

  static getItem(key: string): string | null {
    try {
      if (typeof localStorage !== "undefined") {
        return localStorage.getItem(key);
      }
    } catch (error) {
      console.error(`Failed to get value for key ${key}. Error:`, error);
    }
    return null;
  }

  static removeItem(key: string): void {
    try {
      if (typeof localStorage !== "undefined") {
        localStorage.removeItem(key);
      }
    } catch (error) {
      console.error(`Failed to remove value for key ${key}. Error:`, error);
    }
  }

  static clear(): void {
    try {
      if (typeof localStorage !== "undefined") {
        localStorage.clear();
      }
    } catch (error) {
      console.error("Failed to clear items. Error:", error);
    }
  }
}
